<template>
	<div class="container">
		<div class="content">
			<div class="query-form">
				<div class="query-title">IP规划师考试成绩查询</div>
				<el-form ref="form" :rules="rules" class="exam-form" :model="form" label-position="top" label-width="80px">
					<el-form-item label="姓名：" prop="name">
						<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
					</el-form-item>
					<el-form-item label="准考证号：" prop="examNumber">
						<el-input v-model="form.examNumber" placeholder="请输入您的准考证号"></el-input>
					</el-form-item>
					<el-button type="primary" class="submit-btn" :disabled="!checked" v-preventReClick @click="onSubmit">查询</el-button>
				</el-form>
			</div>
			<!-- 弹窗 -->
			<div class="popupCon" v-show="popup" v-if="examScoresList[0]">
				<div class="query-results">
					<div class="close" @click="closePopup"><img src="@/assets/icons/close.png"></div>
					<div v-if="isPass == 1">
						<div class="medal">
							<img src="@/assets/icons/icon-qualified.png">
						</div>
						<div class="transcript">
							<div class="fractional-line">理论分数：{{examScoresList[0].examScores}}分</div>
							<div class="curfractional-line">实操分数：{{examScoresList[1].examScores}}分</div>
						</div>
						<h2 class="notice">恭喜您通过考试，成绩合格</h2>
						<el-button type="primary" class="appliBtn" :disabled="!checked" @click="goLink">证书申请</el-button>
					</div>
					<div v-else>
						<div class="medal">
							<img src="@/assets/icons/icon-unqualified.png">
						</div>
						<div class="transcript">
							<div class="fractional-line">理论分数：{{examScoresList[0].examScores}}分</div>
							<div class="curfractional-line">实操分数：{{examScoresList[1].examScores}}分</div>
						</div>
						<h2 class="notice">很遗憾您未通过考试，成绩不合格</h2>
						<div class="tishi">您还可以在两年内免费补考一次，请关注考试相关信息</div>
					</div>
					
				</div>
				<!--这里是半透明背景层-->
				<div class="over" @click="closePopup"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				checked: true,
				form: {
					name: '',
					examNumber: '',					
				},
				ipType:null,
				isPass: 0,
				examScoresList:[],
				popup: 0,
				rules: {
					name: [{
						required: true,
						message: '请输入您的姓名',
						trigger: 'blur'
					}, ],
					examNumber: [{
						required: true,
						message: '请输入您的准考证号',
						trigger: 'blur'
					}, ],
				}
			};
		},
		
		methods: {
			onSubmit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$http
							.post('/api/web/common/inquireExamScores', this.form)
							.then(res => {
								this.showPopup();
								this.isPass=res.data.isPass;
								this.ipType=res.data.ipType;
								this.examScoresList = res.data.examScoresList
							})
							.catch(err => {
								this.$toast(err.msg)
							})
					} else {
						this.$toast('请正确填写姓名和准考证号')
						return false;
					}
				});

			},
			//打开弹窗
			showPopup() {
				this.popup = 1;
			},
			//关闭弹窗
			closePopup() {
				this.popup = 0;
			},
			goLink() {
				this.$router.push({
					name: 'ResultsEnroll',
					query: {
						examNumber:this.form.examNumber,
						ipType:this.ipType
					}
				})
			},
		},
	};
</script>

<style lang='less' scoped>
	.container {
		width: 100%;
		height: 610px;
		padding: 30px 0;
		background-color: #f5f5f5;

		.content {
			width: var(--container-width);
			margin: 0 auto;
			padding: 60px 85px;
			background-image: url("~@/assets/images/query-bg.jpg");
			background-size: cover;

			.login-top {
				width: 304px;
			}

			.query-form {
				width: 400px;
				padding: 40px 30px 30px;
				border-radius: 6px;
				background: #fff;

				.labels {
					height: 16px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					text-align: left;
					margin-bottom: 20px;
				}

				.query-title {
					width: 100%;
					font-size: 20px;
					font-weight: bold;
					color: #333333;
					text-align: center;
					margin-bottom: 30px;
				}
				/deep/.exam-form{
					.el-form-item__label{
						width: 100%;
						padding-bottom: 0;
					}
					.el-input__inner {
						background: #ffffff;
						border: 1px solid #DADADA;
						border-radius: 4px;
						font-size: 16px;
					}
				}				

				.submit-btn {
					width: 120px;
					height: 44px;
					margin-top: 10px;
					margin-bottom: 10px;
					background: #D71A18;
					border-radius: 22px;

					&.is-disabled {
						background: #DADADA;
						border: 1px solid #DADADA;
					}
				}

			}

			.popupCon {
				position: fixed;
				left: 0;
				top: 0;
				width: 100%;
				height: 100vh;

				.over {
					width: 100%;
					height: 100vh;
					background-color: rgba(0, 0, 0, .8);
				}
			}

			.query-results {
				position: absolute;
				left: 50%;
				top: 50%;
				width: 450px;
				height: 395px;
				margin-top: -198px;
				margin-left: -225px;
				padding-top: 135px;
				border-radius: 30px;
				background-color: #ffffff;
				z-index: 99;

				.close {
					position: absolute;
					right: 0;
					top: -120px;
					width: 48px;
					height: 48px;
					cursor: pointer;
					z-index: 100;

					img {
						display: block;
						width: 100%;
					}
				}

				.medal {
					position: absolute;
					top: -120px;
					width: 450px;

					img {
						display: block;
						width: 100%;
					}
				}
				.transcript{
					font-size: 22px;
					line-height: 32px;
					margin-top: 15px;
					.fractional-line{
						color: #333333;
						margin-bottom: 10px;
					}
					.curfractional-line{
						color: #D71A18;
					}
				}
					
				.notice {
					width: 100%;
					font-size: 24px;
					font-weight: bold;
					color: #CA6521;
					margin-top: 20px;
					margin-bottom: 30px;
				}
				.tishi{
					font-size: 16px;
					line-height: 30px;
					color: #a3a3a3;
					text-align: center;
					padding: 0 80px;
				}
				.appliBtn {
					width: 140px;
					height: 50px;
					background: #CA6521;
					border-radius: 25px;
					font-size: 20px;
					font-weight: bold;
					color: #FFFFFF;
					border: none;
					line-height: 50px;
					cursor: pointer;
					padding: 0;
				}
			}
		}
	}
</style>
